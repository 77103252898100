<template>
  <!-- 
    画面: オプション新規登録
    用途: オプションを新規登録する
   -->
  <div>

    <!-- #region TODO -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("optionCreatePage.pageTitle") }}</strong>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">
              <!-- #region オプション名 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("optionCreatePage.name") }}</strong></CCol>
                <CCol>
                  <CInput v-model="request.name" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.name.$dirty &&
                      !$v.request.name.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("optionCreatePage.name") })}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.name.$dirty &&
                      !$v.request.name.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("roomCategoryEditPage.name"),vMax: $v.request.name.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion オプション名 -->

              <!-- #region 単価 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("optionCreatePage.price") }}</strong></CCol>
                <CCol>
                  <CInput v-model="request.price" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.price.$dirty &&
                      !$v.request.price.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("optionCreatePage.price") })}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.price.$dirty &&
                      !$v.request.price.numeric
                    "
                  >
                    {{ $t("validations.numeric", { vName: $t("optionCreatePage.price") })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 単価 -->

              <!-- #region 基本提供数 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("optionCreatePage.inventory") }}</strong></CCol>
                <CCol>
                  <CInput v-model="request.inventory" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.inventory.$dirty &&
                      !$v.request.inventory.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("optionCreatePage.inventory") })}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.inventory.$dirty &&
                      !$v.request.inventory.numeric
                    "
                  >
                    {{ $t("validations.numeric", { vName: $t("optionCreatePage.inventory") })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 基本提供数 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("optionCreatePage.description") }}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="request.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.description.$dirty &&
                      !$v.request.description.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("roomCategoryEditPage.description"),vMax: $v.request.description.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #region 確認ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onCheckClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
              <!-- #endregion 確認ボタン -->

            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion TODO -->

    <!-- #region Check -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">
        <!-- #region オプション名 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("optionCreatePage.name") }}</strong></CCol>
          <CCol><span> {{ request.name }} </span></CCol>
        </CRow>
        <!-- #endregion オプション名 -->

        <!-- #region 単価（円） -->
        <CRow>
          <CCol class="label"><strong>{{ $t("optionCreatePage.price") }}</strong></CCol>
          <CCol><span> {{ request.price }} </span></CCol>
        </CRow>
        <!-- #endregion 単価（円） -->

        <!-- #region 基本提供数 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("optionCreatePage.inventory") }}</strong></CCol>
          <CCol><span> {{ request.inventory }} </span></CCol>
        </CRow>
        <!-- #endregion 基本提供数 -->

        <!-- #region 説明 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("optionCreatePage.description") }}</strong></CCol>
          <CCol><span> {{ request.description }} </span></CCol>
        </CRow>
        <!-- #endregion 説明 -->

      </CForm>
      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton
          @click="checkModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton
          color="success"
          @click="onRegisterClicked()"
        >
          <i class="icon cil-save mr-1"></i> {{
          $t("common.register")
        }}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion Check -->

    <!-- #region Success -->
    <SuccessModal
        :successModal="successModal"
        @close="successModal = $event"
      />
    <!-- #endregion Success -->

    <!-- #region Error -->
    <ErrorModal
        :errorModal="errorModal"
        :errorMessage="errorMessage"
        @close="errorModal = $event"
      />
    <!-- #endregion Error -->

  </div>
</template>

<script>
// import i18n from "../i18n";
import axios from "axios";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";
import {
  required,
  numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {

  name: "RoomTypeCreate",

  components: {
    SuccessModal,
    ErrorModal
  },

  computed: {
    activeStatus() {
      console.log(this.$v.request)
      return this.$v.request.$invalid ? 'inactive' : 'active'
    },

    showSuccessModal() {
      return this.successModal || this.deleteSuccessModal;
    },
  },
  
  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
    deleteSuccessModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {

      //#region Flag

      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,

      //#endregion Flag

      uniqueId:this.$store.state.uniqueId,
      //#region request
      request: {
        name: "",
        price: 0,
        inventory: 0,
        description: "",
      },

      //#endregion request


      //#region Response data

      response: [],

      //#endregion Response data

    }
  },

  validations() {
    return {
      request: {
        name: {
          required,
          maxLength:maxLength(50)
         },
        price: { 
          required,
          numeric
          },
        inventory: { 
          required,
          numeric
          },
        description: {
          maxLength:maxLength(1000)
        },
      }
    };
  },

  methods: {

    //#region Event

    /** 確認ボタン押下 */
    onCheckClicked() {
      this.$v.request.$invalid ?
        this.$v.request.$touch() :
        this.checkModal = true;
    },

    /** 登録ボタン押下 */
    onRegisterClicked() {
      this.reqPost();
    },

    /** キャンセルボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    //#endregion Event

    //#region Request

    /** オプション登録リクエスト */
    reqPost() {
      this.loading = true;

      const url = `/rest/hotel/${this.uniqueId}/option`;
      const body = {
        name : this.request.name,
        description: this.request.description,
        price : this.request.price,
        inventory : this.request.inventory,
      }

      this.loading = true;
      axios
      .post(url,body,
          {headers: { "Accept-Language": this.$i18n.locale },}
        )
      .then((resp) => {
        const data = resp.data;
        console.log(data);

        this.request={};

        this.loading = false;
        this.checkModal = false;
        this.successModal = true;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
  
    //#endregion Request


    //#region Method

    backPage() {
      this.$router.go(-1);
    },

    //#endregion Method

  }
}
</script>
